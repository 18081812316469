import React from "react"
import Img from "gatsby-image/withIEPolyfill"
import FixedRatioModal from './fixed-ratio'
import ModalCloseButton from './close-button'

export default function ImageModal({image, onClickClose, ...props}) {
  const height = image.childImageSharp.fluid.presentationHeight
  const width = image.childImageSharp.fluid.presentationWidth
  const ratio = height / width;
  return (
    <FixedRatioModal ratio={ratio} maxWidth={width} maxHeight={height}
      {...props}
      aria-labelledby="contained-modal-title-vcenter">
      <ModalCloseButton onClick={onClickClose} />
      <Img fluid={image.childImageSharp.fluid}
            objectFit="inside" 
            objectPosition="50% 50%" />
    </FixedRatioModal>
  )
}
