import React from "react"
import Button from 'react-bootstrap/Button'
import {addClassName} from '../../utils'

export default function ModalCloseButton({ onClick, ...props}) {
  return (
    <Button variant="outline-light" onClick={onClick} aria-label="Close" 
      {...addClassName('btn-modal-close', props)}>
      <span aria-hidden="true">&times;</span>
    </Button>
  )
}