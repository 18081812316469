import React from "react"
import HandleButton from "./handle-button"
import ImageModal from "../modals/image"

export default function ImageHandle({ object, text }) {
  const [show, setShow] = React.useState(false);
  return (
    <>
      <HandleButton onClick={() => setShow(true)} 
        text= { text || "Afbeelding"} />
      <ImageModal image={object.image}
        show={show}
        onHide={() => setShow(false)}
        onClickClose={() => setShow(false)} />
    </>
  )
}