import React from "react"
import Modal from 'react-bootstrap/Modal'
import { addClassName } from '../../utils'

export default function FullScreenModal({ children, ...props }) {
  return (
    <Modal centered {...addClassName('full-screen', props)}>
      <Modal.Body>
        {children}
      </Modal.Body>
    </Modal>
  )
}
  