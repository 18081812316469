import React from "react"
import HandleButton from "./handle-button"
import SeriesModal from "../modals/series"

export default function SeriesHandle({ series, text }) {
  const [show, setShow] = React.useState(false);
  return (
    <>
      <HandleButton onClick={() => setShow(true)} 
        text={ text || "Serie"} />
      <SeriesModal
        series={series}
        show={show}
        onClickClose={() => setShow(false)}
        onHide={() => setShow(false)} />
    </>
  )
}