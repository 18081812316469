import React from "react"
import Modal from 'react-bootstrap/Modal'
import { addClassName } from '../../utils'

export default function FixedRatioModal({ ratio, maxWidth, maxHeight, children, ...props }) {
  /* This is tricky to get right. 
  The trick is to compute the height and with using the largest dimension of
  the image, but relative to the viewport. So if an image has width 2 and
  height 1, so a height/width ratio of 1/2, set 
    
    width: 100vw
    height: ratio * 100vw

  However, if the viewport becomes too small to accomodate ratio * 100vw, we
  must decrease the images height, and its width accordingly. We do so by 
  setting the maxHeight and maxWidth:

    maxWidth: (1 / ratio) * 100vh
    maxHeight: 100vh

  Finally, we take the minimum of the maxWidth/maxHeight computed so var and 
  the absolute maxWidth/maxHeight in px, passed to the component as an property.
  This is to prevent the image from blowing up and losing quality.
  If the image is in portrait mode everything is reversed.
  */
  const styles = {}
  if(ratio < 1) { // Landscape
    styles.width = "var(--modal-width)"
    styles.height = `calc(${ratio} * var(--modal-width))`
    styles.maxWidth = `min(${maxWidth}px, calc(${1 / ratio} * var(--modal-height)))`
    styles.maxHeight = `min(${maxHeight}px, var(--modal-height))`
  } else { // Portrait
    styles.width = `calc(${1 / ratio} * var(--modal-height))`
    styles.height = "var(--modal-height)"
    styles.maxWidth = `min(${maxWidth}px, var(--modal-width))`
    styles.maxHeight = `min(${maxHeight}px, calc(${ratio} * var(--modal-width)))`
  }
  
  return (
    <Modal centered {...addClassName('fixed-ratio', props)}>
      <Modal.Body style={styles}>
        {children}
      </Modal.Body>
    </Modal>
  );
}