import React from "react"
import HandleButton from "./handle-button"
import AudioModal from "../modals/audio"

export default function AudioHandle({ object, text }) {
  const [show, setShow] = React.useState(false);
  return (
    <>
      <HandleButton onClick={() => setShow(true)} 
        text={ text || "Audio"} />
      <AudioModal
        object={object}
        show={show}
        onClickClose={() => setShow(false)}
        onHide={() => setShow(false)} />
    </>
  )
}