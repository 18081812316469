import React from "react"
import _ from "lodash"
import GoogleMapReact from 'google-map-react';
import FullScreenModal from './full-screen'
import ModalCloseButton from './close-button'

export default function MapModal({lat, lng, zoom, onClickClose, mapOptions, ...props}) {
  const createMapOptions = function (maps) {
    const defaultMapOptions = {
      panControl: false,
      mapTypeControl: false,
      fullscreenControl: false,
      mapTypeId: "satellite"
    }
    return _.defaults(mapOptions, defaultMapOptions)
  }

  return (
    <FullScreenModal {...props}>
      <ModalCloseButton onClick={onClickClose} />
      { 
        typeof window !== 'undefined'
        ? (
          <div className="map-container">
            <GoogleMapReact
              options={createMapOptions}
              bootstrapURLKeys={{ key: 'AIzaSyA0HmPVhh7JSd7hwBWt6aPe4PSnBwOTFmA' }}
              defaultCenter={{lat: lat, lng: lng}}
              defaultZoom={zoom}
            >
            </GoogleMapReact>
          </div>
          ) 
        : null 
      }
    </FullScreenModal>
  )
}