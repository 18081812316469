import React from "react"
import Modal from 'react-bootstrap/Modal'
import ReactAudioPlayer from 'react-audio-player';
import ModalCloseButton from './close-button'

export default function AudioModal({ object, onClickClose, ...props }) {
  return (
    <Modal centered {...props}>
      <Modal.Body className="p-0 bg-dark">
        <ModalCloseButton onClick={onClickClose} />
        <ReactAudioPlayer
          src={`/audio/${object.audio.relativePath}`}
          autoPlay
          controls
          style={{width: '100%', marginTop: "5rem"}}
        />
        <p className="p-3 text-light">{object.title}</p>
      </Modal.Body>
    </Modal>
  )
}