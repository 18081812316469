import React from "react"
import HandleButton from "./handle-button"
import MapModal from "../modals/map"

export default function MapHandle({ object, text }) {
  const [show, setShow] = React.useState(false);
  return (
    <>
      <HandleButton onClick={() => setShow(true)} 
        text= { text || "Satellietbeelden"} />
      <MapModal
        lat={object.latitude}
        lng={object.longitude}
        zoom={object.zoom}
        show={show}
        onHide={() => setShow(false)}
        onClickClose={() => setShow(false)}
        aria-labelledby="contained-modal-title-vcenter" />
    </>
  )
}