import React from "react"
import _ from "lodash"
import Card from 'react-bootstrap/Card'
import Img from "gatsby-image/withIEPolyfill"
import Alert from "react-bootstrap/Alert"

import ImageHandle from "../modal-handles/image"
import MapHandle from "../modal-handles/map"
import SeriesHandle from "../modal-handles/series"
import AudioHandle from "../modal-handles/audio"

// https://stackoverflow.com/questions/55736264/using-objectfit-in-gatsby-image-props
// https://github.com/gatsbyjs/gatsby/tree/master/packages/gatsby-image#polyfilling-object-fitobject-position-for-ie

function isMapDrawing(object) {
  if(object.genres === null || object.genres.length === 0) return false
  for(let i = 0; i < object.genres.length; i++) {
    if(object.genres[i].title === 'globe') return true
  }
  return false
}

function isDrawing(object) {
  if(object.genres === null || object.genres.length === 0) return false
  for(let i = 0; i < object.genres.length; i++) {
    if(object.genres[i].title === 'drawing') return true
  }
  return false
}

function ObjectPropertiesList({ object }) {
  let year = object.year
  let exhibition = object.exhibition
  let collaborator = object.collaborator
  let code = object.code
  let country = object.country
  if(object.linked_object) {
    const linked = object.linked_object;
    if(linked.year) year = linked.year
    if(linked.exhibition) exhibition = linked.exhibition
    if(linked.collaborator) collaborator = linked.collaborator
    if(linked.code) code = linked.code
    if(linked.country) country = linked.country
  }

  if(collaborator) {
    collaborator = _.startsWith(collaborator, 'Foto') ? collaborator : `I.s.m. ${collaborator}`
  }

  if(isMapDrawing(object)) country = ''

  const all_properties = {year, exhibition, country, collaborator, code}
  const properties = {}
  _.forEach(all_properties, (value, prop) => {
    if(value !== '' & value !== null) properties[prop] = value
  })
  const keys = _.keys(properties)
  return (
    <>
      {_.map(properties, function(value, prop) {
        const i = keys.indexOf(prop)
        return (
          <span key={i}>
            <span className={prop}>{value}</span>
            {i < keys.length - 1 ? " • " : null}
          </span>
          )
        }
      )}
    </>
  )
}

export default function Thumbnail({ object }) {
  if(object.type == 'audio') {
    console.warn('Cannot display audio thumbnail')
    return <p>Link to audio is missing.</p>
  }
  
  let description = object.description
  if(object.linked_object) {
    const linked = object.linked_object;
    if(linked.description) description = linked.description
  }

  const handlesRef = React.createRef();
  const handleThumbnailClick = () => {
    const buttons = handlesRef.current.getElementsByClassName('btn')
    if(buttons.length > 0) buttons[0].click()
  }
  const image = object.thumbnail.childImageSharp
  /*
  Thumbnails have a max width of 400px, and this is how they are queried.
  The images themselves have a max height depending on the viewport, to ensure
  that the header is not disproportionally high. */
  return (
    <Card className="shadow mb-4" style={{ minWidth: 250, maxWidth: 400}}>
      <Card.Header className="p-0 justify-content-center" onClick={handleThumbnailClick}
        style={{cursor: "pointer"}}>
        {
          image
            ? <Img fluid={image.fluid}
                objectFit="cover"
                objectPosition="50% 50%"
                style={{
                  height: "40vh", 
                  // Prevent images from blowing up. This means that some \
                  // headers will be smaller
                  maxHeight: image.fluid.presentationHeight,
                  maxWidth: image.fluid.presentationWidth,
                  borderTopLeftRadius: "0.75rem",
                  borderTopRightRadius: "0.75rem"
                }}/>
            : null
        }
      </Card.Header>
      <Card.Body>
        <Card.Title>{object.title}</Card.Title>
        <Card.Text className="text-muted"> 
          {description}
        </Card.Text>
        <Card.Text className="small text-muted">
          <ObjectPropertiesList object={object} />
        </Card.Text>
        <div ref={handlesRef}>
          { 
            isDrawing(object)
            ? <ImageHandle object={object} text="Tekening" />
            : object.linked_object === null
              ? <ImageHandle object={object} text="Afbeelding" />
              : null
          } 
          {object.linked_object === null
            ? null
            : <>
                {/* To do show series with both the b/w image and the color one */}
                {
                  object.linked_object.type === 'image'
                    ? <ImageHandle object={object.linked_object} text="Afbeelding" /> 
                    : null
                }
                {
                  object.linked_object.type === 'map'
                    ? <MapHandle object={object.linked_object} />
                    : null 
                }
                {
                  object.linked_object.type === 'audio'
                    ? <AudioHandle object={object.linked_object} />
                    : null 
                }
              </>
          }
          { object.linked_series === null
            ? null
            : <SeriesHandle series={object.linked_series} />
          }
        </div>
      </Card.Body>
    </Card>
  )
}