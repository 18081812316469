import React from "react"
import Img from "gatsby-image/withIEPolyfill"
import Carousel from "react-bootstrap/Carousel"
import FullScreenModal from './full-screen'
import ModalCloseButton from './close-button'
import { addClassName } from '../../utils'

function ImageCarouselItem({ object, ...props }) {
  return (
    <Carousel.Item {...props}>
      <Img fluid={object.image.childImageSharp.fluid}
        objectFit="inside" 
        objectPosition="50% 50%"/>
    </Carousel.Item>
  )
}

export default function SeriesModal({series, onClickClose, ...props}) {
  return (
    <FullScreenModal {...addClassName('series', props)}
      aria-labelledby="contained-modal-title-vcenter">
      <ModalCloseButton onClick={onClickClose} />
      <Carousel slide={false} style={{height: "100%"}} className="bg-dark">
        {series.objects.map(object => 
          <ImageCarouselItem object={object} key={object.object_id} />
        )}
      </Carousel>
    </FullScreenModal>
  );
}
